// axios.ts

import axios from 'axios';
import { ref } from "vue";
import { getAuthToken } from '@/utils/auth0'
import axiosRetry from 'axios-retry';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Client-Location': Intl.DateTimeFormat().resolvedOptions().timeZone,
};
const apiUrl = import.meta.env.VITE_API_ROOT;

export const axiosRoot = axios.create({
  baseURL: apiUrl,
  headers: defaultHeaders,
});

axiosRetry(axiosRoot, {
  retries: 3, // Number of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Exponential backoff
  },
  retryCondition: (error) => {
    return error.code === 'ERR_NETWORK'; // Only retry on network errors
  },
});

export async function useAxios() {
  const token = ref('');
  const accessToken = await getAuthToken();
  token.value = accessToken;
  
  if (token.value) {
    axiosRoot.defaults.headers.Authorization = `Bearer ${token.value}`;
  }
  
  return { axiosRoot };
}