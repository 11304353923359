import { ref, watch } from 'vue';
import { useUserStore } from '@/stores/user'; // Assuming you have a Pinia store for user
import { strObjInterpolation } from '@/utils/text.js';
import { es, ca, enUS } from 'date-fns/locale'

const mapLangFns = { 
  es_ES: es,
  ca_ES: ca,
  en_US: enUS
}

/**
 * Navigates inside `obj` with `path` string, example 'path.to.variable'
 *
 * Usage:
 * navigate({a: {b: true}}, "a.b") // returns true
 *
 * Returns null if variable is not found
 */

const objNavigate = (obj, path) => {
  path = path.split('.');
  try {
    return path.reduce((a, v) => a[v], obj);
  } catch {
    return null;
  }
};

export function useI18n(i18nLocales) {
  const userStore = useUserStore();
  const i18nLang = ref('es_ES');
  const dateLocale = mapLangFns[i18nLang.value];


  // Method to get translated string with optional interpolations
  const i18n = (str, interpolations) => {

    str = objNavigate(i18nLocales[i18nLang.value], str) || `{{ ${str} }}`;
    return strObjInterpolation(str, interpolations);
  };

  // Method to set the language
  const i18nSetLang = (lang) => {
    i18nLang.value = lang;
    dateLocale.value = mapLangFns[lang];
  };

  // Watch for userLanguagePreference changes
  watch(() => userStore.userLanguagePreference, (newVal) => {
    i18nSetLang(newVal);
  }, { immediate: true });

  // Set initial language on mount
  i18nSetLang(userStore.userLanguagePreference || 'es');

  return {
    i18n,
    i18nSetLang,
    i18nLang,
    dateLocale
  };
}
