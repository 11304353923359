// const S3_BUCKET = 'https://public-images-miinta.s3.eu-south-2.amazonaws.com'
const CLOUDFRONT = 'https://d1r9pyptuo54ba.cloudfront.net'
const CHAT_APP = `${CLOUDFRONT}/platform/chatApp`
const CLIENT_APP = `${CLOUDFRONT}/platform/clientApp`
const ICONS = `${CLOUDFRONT}/icons`

const icons = {
  home: `${ICONS}/home.png`,
  therapist2: `${ICONS}/psychiatrist.png`,
  therapist: `${ICONS}/woman.png`,
  settings: `${ICONS}/cogwheel.png`,
  activity: `${ICONS}/pilates.png`,
  more: `${ICONS}/more.png`,
  videoConference: `${ICONS}/video-conference.png`,
}

const publicImages = {
  soundWave: `${CHAT_APP}/sound_wave.png`,
  soundWaveWhite: `${CHAT_APP}/sound_wave_white.png`,
  circlePeopleMiinta: `${CHAT_APP}/circle-people-miinta.webp`,
  miintaTeenagerSad: `${CLIENT_APP}/miinta-teenager-sad.png`,
  logoMiinta:  `${CLOUDFRONT}/miinta.jpeg`,	
  longLogoMiinta: `${CLOUDFRONT}/longLogox2.png.png`,
  bookSessionFigures: `${CLIENT_APP}/book-session-figures.png`,
  studientSittingHappy: `${CLIENT_APP}/student-stiing-happy.jpeg`,

}

// merge icons with publicImages
Object.assign(publicImages, icons)

export default publicImages
