import { ref } from 'vue';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
    prompt(): Promise<void>;
}

// Create reactive state
const deferredPrompt = ref<BeforeInstallPromptEvent | null>(null);
const isAppInstalled = ref(window.matchMedia('(display-mode: standalone)').matches 
    || window.matchMedia('(display-mode: window-controls-overlay)').matches);
const promptDismissed = ref(false);

// Listen for the beforeinstallprompt event
window.addEventListener('beforeinstallprompt', (e: Event) => {
    e.preventDefault();
    deferredPrompt.value = e as BeforeInstallPromptEvent;
    promptDismissed.value = false;
});

// Listen for successful installation
window.addEventListener('appinstalled', () => {
    deferredPrompt.value = null;
    isAppInstalled.value = true;
});

export const usePWAInstall = () => {
    const showInstallPrompt = async () => {
        if (isAppInstalled.value) {
            return false;
        }

        if (promptDismissed.value) {
            return false;
        }

        // Debug installation criteria
        const isPWADebug = {
            hasServiceWorker: 'serviceWorker' in navigator,
            isSecureContext: window.isSecureContext,
            hasInstallPrompt: deferredPrompt.value !== null
        };
        

        // Check PWA criteria
        if (!isPWADebug.hasServiceWorker) {
            return false;
        }

        if (!isPWADebug.isSecureContext) {
            return false;
        }

        if (!deferredPrompt.value) {
            return false;
        }

        try {
            // Show the prompt
            await deferredPrompt.value.prompt();
        
            // Wait for the user to respond to the prompt
            const { outcome } = await deferredPrompt.value.userChoice;
            
            if (outcome === 'dismissed') {
                promptDismissed.value = true;
            }
        
            // Clear the deferredPrompt variable
            deferredPrompt.value = null;
            return outcome === 'accepted';
        } catch (error) {
            return false;
        }
    };

    return {
        showInstallPrompt,
        isInstallable: deferredPrompt.value !== null,
        isAppInstalled,
        promptDismissed
    };
};