import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vant/lib/index.css';



import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { usePWAInstall } from '@/config/pwaInstall';



import App from './App.vue'
import router from './router'
import { auth0 } from './utils/auth0'
import { initSentry } from './config/sentry';


usePWAInstall();

const app = createApp(App)

initSentry(app, router);

app.use(router)
app.use(auth0)
app.use(createPinia())
app.mount('#app')
