import { defineStore } from 'pinia';
import { useAxios } from '@/utils/axios';
import type { AxiosResponse } from 'axios';
import { urls } from '@/utils/backendUrls';


export type UserLink = 'resetPassword'  

interface UserInfo {
  mng_user_id: string,
  therapist_id: string | null,
  client_id: string | null,
  user_id: string,
  name: string,
  email: string
  phone_number: string
  description: null | object,
  language_code: string,
  picture: string | null,
  created_at: string,
  last_login: string | null,
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    userLanguagePreference: 'es_ES' as string,
    userInfo: {} as UserInfo,
    userLoading: false,
    userResetPasswordLink: ''
  }),
  getters: {
    getSimpleName(): string {
      return this.userInfo?.name?.split(' ')[0] ? this.userInfo.name.split(' ')[0] : this.userInfo.name;
    }
  },
  actions: {
    setUserLanguage(lang: string | undefined): void {
      if (!lang) {this.userLanguagePreference = 'es_ES';  return;}
      this.userLanguagePreference = lang;
    },
    async updateLastLogin() {
      const lastLogin = this.userInfo.last_login;
      const moreThanADay = lastLogin ? new Date().getTime() - new Date(lastLogin).getTime() > 86400000 : true;
      if (!moreThanADay) { return; }  

      const { axiosRoot } = await useAxios();
      const data = { user_id: this.userInfo.user_id, email: this.userInfo.email };
      await axiosRoot.post(urls.URL_USERMG_COMMAND_UPDATE_LAST_LOGIN, data).then(
        () => this.userInfo.last_login = new Date().toISOString()
      );
    },
    async fetchMyUserInfo(withPicture: boolean = false) {
      const { axiosRoot } = await useAxios();
      const params = {with_picture: withPicture};
      await axiosRoot.get(urls.URL_CHAT_QUERY_GET_MY_USER_INFO, {params}).then(
        async (response: AxiosResponse<any>) => {
          this.userInfo = response.data;
          this.setUserLanguage(this.userInfo.language_code);
          await this.updateLastLogin();
        });
    },
    async generateResetPasswordLink(clientId: string) {
      const { axiosRoot } = await useAxios();
      this.userLoading = true;
      const params = {client_id: clientId};
      await axiosRoot.get(urls.URL_USERMG_QUERY_GET_RESET_PASSWORD_LINK, { params }).then(
        (response: AxiosResponse<any>) => {
          this.userResetPasswordLink = response.data;
        }).finally(() => {
          this.userLoading = false;
        });
    },

    async updatePicture(picture: File) {
      const { axiosRoot } = await useAxios();
      const formData = new FormData();
      formData.append('file', picture);
      formData.append('user_id', this.userInfo.user_id);
      const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
      await axiosRoot.post(urls.URL_CHAT_COMMAND_UPDATE_USER_PICTURE, formData, headers).then(
          async () => await this.fetchMyUserInfo(true)
      );
    }
  },
});
