<template>
  <button 
    class="btn" 
    @click="onClick" 
    :class="[classColor, { 'elevated': elevated }]"
    @mouseover="isHovered = true" 
    @mouseleave="isHovered = false" 
    @mousedown="isClicked = true" 
    @mouseup="isClicked = false"
    :disabled="loading"
  >
    <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
    <span v-if="icon" class="icon" :class="!isMobile? 'me-2' : ''">
      <i class="bi" :class="icon"></i>
    </span>
    <span v-if="text && !isMobile">{{ props.text }}</span>
  </button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const emit = defineEmits(['onClick']);
const props = defineProps<{
  text?: string
  color: 'primary' | 'secondary' | 'tertiary' | 'orange' | 'green' | 'red' | 'blue' | 'yellow' | 'white' | 'purple';
  icon?: string
  loading?: boolean
  mobileVersion?: boolean
  elevated?: boolean
  
}>();

const isHovered = ref(false);
const isClicked = ref(false);
const isMobile =computed(() =>window.innerWidth < 768 && props.mobileVersion);


const onClick = () => {
  emit('onClick');
}

const classColor = computed(() => ({
  'btn-primary': props.color === 'primary',
  'btn-secondary': props.color === 'secondary',
  'btn-tertiary': props.color === 'tertiary',
  'btn-orange': props.color === 'orange',
  'btn-green': props.color === 'green',
  'btn-red': props.color === 'red',
  'btn-blue': props.color === 'blue',
  'btn-yellow': props.color === 'yellow',
  'btn-white': props.color === 'white',
  'btn-purple': props.color === 'purple',
}));
</script>

<style scoped lang="scss">
.btn {
  @extend .text-md--semibold;
  border: none;
  transition: transform 0.1s ease-in-out;
  padding: 5px 20px;
  height: fit-content;
  border-radius: 25px;
  display: flex;
  align-items: center;
  position: relative;
  
  @media (max-width: 576px) {
    padding: 5px 15px;
  }

  &.elevated {
    transform: translateY(-3px);
    border-bottom: 3px solid rgba(0, 0, 0, 0.2);
    padding-top: 6px;
    padding-bottom: 6px;
    
    &:hover {
      transform: translateY(-4px);
      border-bottom: 4px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 5px;
    }
    
    &:active {
      transform: translateY(0);
      border-bottom: 0;
    }
  }

  &-primary {
    background-color: rgba($primary, 1);
    color: white ;
    &:active { background-color: rgba($primary, 0.6); }
  }
  &-secondary {
    background-color: rgba($secondary, 1);
    color: $grey-100 ;
    &:hover { background-color: rgba($secondary, 0.8); }
    &:active { background-color: rgba($secondary, 0.6); }
  }
  &-tertiary {
    background-color: rgba($secondary, 1);
    border: 1px solid rgba($secondary, 0.9);
    color: $grey-100 ;
    &:hover {
      background-color: rgba($secondary, 0.2);
      border: 0.1px solid rgba($black, 1);
    }
    &:active { background-color: rgba($secondary, 0.6); }
  }
  &-orange {
    background-color: rgba($orange-100, 1);
    color: white ;
    &:hover { background-color: rgba($orange-100, 0.8); }
    &:active { background-color: rgba($orange-100, 0.6); }
  }
  &-green {
    background-color: rgba($green-100, 1);
    color: white;
    &:hover { background-color: rgba($green-100, 0.8); }
    &:active { background-color: rgba($green-100, 0.6); }
  }
  &-red {
    background-color: rgba($red-100, 1);
    color: white ;
    &:hover { background-color: rgba($red-100, 0.8); }
    &:active { background-color: rgba($red-100, 0.6); }
  }
  &-blue {
    background-color: rgba($blue-100, 1);
    color: white;
    &:hover { background-color: rgba($blue-100, 0.8); }
    &:active { background-color: rgba($blue-100, 0.6); }
  }
  &-yellow {
    background-color: rgba($yellow-100, 1);
    color: white;
    &:hover { background-color: rgba($yellow-100, 0.8); }
    &:active { background-color: rgba($yellow-100, 0.6); }
  }
  &-white {
    background-color: rgba($white, 1);
    border: 1px solid rgba($grey-400, 0.9) !important;
    &:hover {
      border: 1px solid rgba(black, 0.9) !important;
    }
  }
  &-purple {
    background-color: rgba($purple-100, 1);
    color: white !important;
    &:hover { background-color: rgba($purple-100, 0.8); }
    &:active { background-color: rgba($purple-100, 0.6); }
  }
  
}
</style>