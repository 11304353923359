/*
Meaning:
- UTC: Coordinated Universal Time
- GMT: Greenwich Mean Time
- CET: Central European Time

*/
import { format, parseISO} from 'date-fns';
import { es } from 'date-fns/locale'
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const remainingHours = (lastMessageTime) => {
  if (!lastMessageTime) return 0;
  const lastMessageDate = new Date(lastMessageTime || '');
  const lastMessageUTC = Date.UTC(lastMessageDate.getUTCFullYear(), lastMessageDate.getUTCMonth(), lastMessageDate.getUTCDate(), lastMessageDate.getUTCHours(), lastMessageDate.getUTCMinutes());
  const yesterdayUTC = Date.UTC(yesterday.getUTCFullYear(), yesterday.getUTCMonth(), yesterday.getUTCDate(), yesterday.getUTCHours(), yesterday.getUTCMinutes());
  const diff = lastMessageUTC - yesterdayUTC;
  const hours = Math.ceil(diff / (1000 * 60 * 60));
  const hoursCleaned = hours >= 24 ? 24 : hours < 0 ? 0 : hours;
  return hoursCleaned
};

export const formatDate = (dateStr, include_hour=true) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let day = date.getDate();
  let month = date.getMonth() + 1;  // Months are zero-indexed
  let year = date.getFullYear().toString().slice(-2);  // Get last two digits of year
  let formattedDate = `${day}/${month}/${year} ${hours}h${minutes}`;
  if (!include_hour) {
    formattedDate = `${day}/${month}/${year}`;
  }
  return formattedDate;
};

export const formatDateLocale = (dateStr, locale=es) => {
  if (!dateStr) return '';
  const date = format(parseISO(dateStr || ''), 'MMMM d, yyyy', { locale: locale });
  return date;
}


const i18nLocales = {
  'es_ES': {
    'seconds_ago': 'hace unos segundos',
    'minutes_ago': 'hace %d minutos',
    'hours_ago': 'hace %d horas',
    'yesterday': 'Ayer',
    'days_ago': 'hace %d días',
    'months_ago': 'hace %d meses',
    'years_ago': 'hace %d años',
    'now': 'ahora',
    'tomorrow': 'Mañana',
    'years_left': 'en %d años',
    'months_left': 'en %d meses',
    'days_left': 'en %d días',
    'hours_left': 'en %d horas',
    'minutes_left': 'en %d minutos',
    'seconds_left': 'en unos segundos',
  },
  'en_US': {
    'seconds_ago': 'a few seconds ago',
    'minutes_ago': '%d minutes ago',
    'hours_ago': '%d hours ago',
    'yesterday': 'Yesterday',
    'days_ago': '%d days ago',
    'months_ago': '%d months ago',
    'years_ago': '%d years ago',
    'now': 'now',
    'tomorrow': 'Tomorrow',
    'years_left': 'in %d years',
    'months_left': 'in %d months',
    'days_left': 'in %d days',
    'hours_left': 'in %d hours',
    'minutes_left': 'in %d minutes',
    'seconds_left': 'a few seconds',
  },
  'ca_ES': {
    'seconds_ago': 'fa uns segons',
    'minutes_ago': 'fa %d minuts',
    'hours_ago': 'fa %d hores',
    'yesterday': 'Ahir',
    'days_ago': 'fa %d dies',
    'months_ago': 'fa %d mesos',
    'years_ago': 'fa %d anys',
    'now': 'ara',
    'tomorrow': 'Demà',
    'years_left': 'en %d anys',
    'months_left': 'en %d mesos',
    'days_left': 'en %d dies',
    'hours_left': 'en %d hores',
    'minutes_left': 'en %d minuts',
    'seconds_left': 'En uns segons',
  }
};


export const timeAgo = (dateAsString, language='es_ES') => {
  const now = new Date();
  const date = new Date(dateAsString);
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const locale = i18nLocales[language] || i18nLocales['es_ES'];

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return locale['years_ago'].replace('%d', interval);
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return locale['months_ago'].replace('%d', interval);
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return locale['days_ago'].replace('%d', interval);
  }
  if (interval === 1) {
    return locale['yesterday'];
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return locale['hours_ago'].replace('%d', interval);
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return locale['minutes_ago'].replace('%d', interval);
  }
  return locale['seconds_ago'];
};


export const timeLeft = (futureDateString, language = 'es_ES') => {
  const now = new Date();
  const futureDate = new Date(futureDateString);
  const secondsLeft = Math.floor((futureDate.getTime() - now.getTime()) / 1000);

  const locale = i18nLocales[language] || i18nLocales['es_ES'];

  if (secondsLeft <= 0) {
    return timeAgo(futureDateString, language);
  }

  const intervals = [
    { label: 'years_left', seconds: 31536000 },
    { label: 'months_left', seconds: 2592000 },
    { label: 'days_left', seconds: 86400 },
    { label: 'hours_left', seconds: 3600 },
    { label: 'minutes_left', seconds: 60 },
    { label: 'seconds_left', seconds: 1 },
  ];

  for (const interval of intervals) {
    const time = Math.floor(secondsLeft / interval.seconds);
    if (time >= 1) {
      if (interval.label === 'days_left' && time === 1) {
        return locale['tomorrow'] ;
      }
      return locale[interval.label].replace('%d', time);
    }
  }

  return locale['seconds_left'] ;
};